import React from "react";
import { graphql } from "gatsby";
import _ from "lodash";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Gallery from "../components/Gallery";

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    
    const images = _(posts)
                  .map(({node}) => (
                    {
                      title: node.frontmatter.title, 
                      image: node.frontmatter.cover.childImageSharp.fluid,
                      slug: node.fields.slug
                    }))
                  .value()

    return (
      <Layout location={this.props.location} title={siteTitle} displayNav>
        <SEO
          title="Tất cả mẩu nhỏ"
          keywords={[`blog`, `sơn tăng`, `chuyện của Sơn`, `chuyen cua Son`, `son tang`]}
        />
        <div style={{flex: 1}}>
            <Gallery images={images} itemsPerRow={[1, 1, 2, 3]}/>
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          facebook,
          instagram
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY")
            title
            moods
            place
            cover {
              childImageSharp {
                ... on ImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
